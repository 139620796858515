<template>
  <Calendar :showIcon="true" icon="pi pi-clock" :placeholder="label" :showTime="true" :timeOnly="true" class="calendar" />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Fecha",
    },
  },
}
</script>

<style scoped>
  .calendar {
    width: 350px;
    height: 50px;
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 2px solid var(--primary) !important;
    box-shadow: none !important;
    font-family: "klavika" !important;
    box-shadow: 0 10px 5px -5px rgba(35, 141, 130, 0.15) !important;
    margin-bottom: 0.5rem;
  }
  .calendar::placeholder {
    color: var(--primary) !important;
  }
</style>