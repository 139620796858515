<template>
  <Textarea :autoResize="true" rows="8" class="textarea" />
</template>

<script>
export default {};
</script>

<style scoped>
.textarea {
  width: 350px;
  border-radius: 10px !important;
  border: 2px solid var(--primary) !important;
  box-shadow: none !important;
  font-family: "klavika" !important;
  box-shadow: 0 10px 50px rgba(35, 141, 130, 0.15) !important;
  margin: 1rem 0 0.5rem 0;
}
</style>
